import { SkeletonLoader } from "@wisr/react-ui";
import React, { useRef } from "react";
import { analyticsCustomEvent } from "../../../../shared/analytics/analytics.utils";
import { isNotEmpty } from "../../../../shared/common.utils";
import { BRAND_WEBSITE } from "../../../../shared/url.consts";
import { useGetUser } from "../../../../user/user.hooks";
import { NavLink } from "../../../shared/nav-link/nav-link.component";
import { WidgetComponent } from "../../../widget.component";
import localStyle from "./personalised-rate-estimate-widget.scss";
import image from "../../../../assets/images/brain-head.svg";
import { useDispatch } from "react-redux";
import {
  useGetPersonalisedRateEstimate,
  useGetPersonalisedRateEstimateStatus,
} from "./personalised-rate-estimate-widget.hooks";
import { FetchStatus } from "../../../../shared/redux.consts";
import { MoneyMomentCopy } from "../../../../types/personalised-rate-estimate";
import { EstimateForm } from "./estimate-form/estimate-form";
import { fetchPersonalisedRateEstimate } from "./personalised-rate-estimate-widget.thunk";
import { getWidgetIcon } from "./personalised-rate-estimate-widget.utils";
import { fetchLoanParameters } from "../../../../loans/parameters/loan-parameters.thunk";
import {
  useGetLoanParameters,
  useGetLoanParametersStatus,
} from "../../../../loans/parameters/loan-parameters.hooks";
import { PartnerReferralWidget } from "../../referrals/partner-referral-widget";
import {
  useGetDrivaEligible,
  useGetEstimateEligible,
  useGetScores,
  useScoreProcessing,
} from "../../../credit/score/score-credit-widget.hooks";
import { isDefined, useLoadingStatus } from "@wisr/common";
import { GETSCORE_CREDIT_LOADING } from "../../../credit/score/score-credit-widget.epic";
import { scoreCreditGetAction } from "../../../credit/score/score-credit-widget.actions";

const defaultLimit = { min: 5000, max: 62000 };
export const PersonalisedRateEstimateWidget: React.FC = () => {
  const user = useGetUser();
  const dispatch = useDispatch();
  const personalisedRateEstimate = useGetPersonalisedRateEstimate();
  const personalisedRateEstimateStatus = useGetPersonalisedRateEstimateStatus();
  const loanParameters = useGetLoanParameters();
  const loanParametersStatus = useGetLoanParametersStatus();
  const creditScores = useGetScores();
  const scoreProcessing = useScoreProcessing(creditScores);
  const [scoreLoading] = useLoadingStatus(GETSCORE_CREDIT_LOADING);
  const isEstimateEligible = useGetEstimateEligible();
  const isDrivaEligible = useGetDrivaEligible() && !isEstimateEligible;
  const isFirstUpdate = useRef(true);
  const [displayText, setDisplayText] = React.useState<MoneyMomentCopy | null>(
    null
  );
  const widgetLoading =
    !displayText ||
    scoreProcessing.status ||
    !isDefined(creditScores) ||
    scoreLoading;
  const displayName =
    isNotEmpty(user) && user.info && user.info.firstName
      ? `${user.info.firstName}`
      : null;
  const icon = getWidgetIcon(displayText?.pictogram);
  const limits = loanParameters?.rateEstimate?.limits ?? {
    secure: defaultLimit,
    unsecure: defaultLimit,
  };
  const loanLimit =
    displayText?.moment === "Vehicle" ? limits.secure : limits.unsecure;
  const defaultIneligibleText =
    "Credit scores change all the time. Point them in the right direction with our tips and tricks.";
  const SCORE_DISPATCH_DELAY = 5000;

  React.useEffect(() => {
    if (personalisedRateEstimateStatus === FetchStatus.IDLE) {
      dispatch(fetchPersonalisedRateEstimate());
    }
    if (loanParametersStatus === FetchStatus.IDLE) {
      dispatch(fetchLoanParameters());
    }
  }, [dispatch, loanParametersStatus, personalisedRateEstimateStatus]);

  React.useEffect(() => {
    if (isNotEmpty(personalisedRateEstimate)) {
      setDisplayText(
        personalisedRateEstimate[
          Math.floor(Math.random() * personalisedRateEstimate.length)
        ]
      );
    }
  }, [personalisedRateEstimate]);

  React.useEffect(() => {
    if (isEstimateEligible && isFirstUpdate.current && displayText) {
      isFirstUpdate.current = false; // stop retriggering this block after firing once
      analyticsCustomEvent("presentedDashboardOffer", {
        "Dashboard offer": "Rate estimate",
        Location: "Dashboard credit scores home",
        Personalisation:
          displayText.moment === "Other" ? "Default" : displayText.moment,
      });
    }
  }, [displayText, isEstimateEligible]);

  React.useEffect(() => {
    if (creditScores?.formSubmitted) {
      setTimeout(() => {
        dispatch(scoreCreditGetAction());
      }, SCORE_DISPATCH_DELAY);
    }
  }, [dispatch, creditScores]);

  if (widgetLoading) {
    return (
      <WidgetComponent widgetName="loading" widgetType="other">
        <style jsx>{localStyle}</style>
        <div className="loading-wrapper">
          <SkeletonLoader lines={11} />
        </div>
      </WidgetComponent>
    );
  }

  if (isEstimateEligible) {
    return (
      <WidgetComponent widgetName="rate-estimate" widgetType="other">
        <style jsx>{localStyle}</style>
        <div className={`cta-widget eligible ${displayText.color}`}>
          <div className="icon">{icon}</div>
          <div className="widget-title">
            <h2>
              {displayText.title ? (
                displayText.title
              ) : (
                <>
                  The smart way
                  <br /> to borrow
                </>
              )}
            </h2>
          </div>
          <EstimateForm
            displayText={displayText}
            minLimit={loanLimit.min}
            maxLimit={loanLimit.max}
          />
        </div>
      </WidgetComponent>
    );
  }

  if (isDrivaEligible) {
    return <PartnerReferralWidget />;
  }

  return (
    <WidgetComponent widgetName="rate-estimate" widgetType="other">
      <style jsx>{localStyle}</style>
      <div className="cta-widget ineligible">
        <div className="image">
          <img src={image} />
        </div>
        <h2 className="widget-title">
          Get those numbers up
          {displayName && `, ${displayName}`}!
        </h2>
        <p className="description">{defaultIneligibleText}</p>
        <NavLink
          className="article-link"
          external
          to={`${BRAND_WEBSITE}/smart-guides/credit-scores-101-guide`}
        >
          Read our Smart Guide
        </NavLink>
      </div>
    </WidgetComponent>
  );
};
