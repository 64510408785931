import { useSelector } from "react-redux";
import { AppStore } from "../../store";
import { LoanParametersResponse } from "../../types/loan-parameters";
import {
  selectLoanParameters,
  selectLoanParametersStatus,
} from "./loan-parameters.slice";

export const useGetLoanParameters = () =>
  useSelector<AppStore, LoanParametersResponse>(selectLoanParameters);

export const useGetLoanParametersStatus = () => {
  return useSelector<AppStore, string>(selectLoanParametersStatus);
};
