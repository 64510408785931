import { toCurrency, useForm } from "@wisr/common";
import { Button, ButtonRow, Slider } from "@wisr/react-ui";
import React from "react";
import { analyticsCustomEvent } from "../../../../../shared/analytics/analytics.utils";
import { RATE_ESTIMATE_BID } from "../../../../../shared/bid.consts";
import { QUOTE_FORM } from "../../../../../shared/url.consts";
import { MoneyMomentCopy } from "../../../../../types/personalised-rate-estimate";
import { useGetUser } from "../../../../../user/user.hooks";
import { getLoanPurpose } from "../personalised-rate-estimate-widget.utils";

interface EstimateFormProps {
  displayText: MoneyMomentCopy;
  minLimit: number;
  maxLimit: number;
}

export const EMPTY_RATE_ESTIMATE_DATA: any = {
  loanAmount: 5000,
};

export const EstimateForm: React.FC<EstimateFormProps> = ({
  displayText,
  minLimit,
  maxLimit,
}) => {
  const user = useGetUser();
  const { formData, setValue } = useForm(EMPTY_RATE_ESTIMATE_DATA);
  const purpose = getLoanPurpose(displayText.moment);
  const widgetOfferLink = `${QUOTE_FORM}?wcToken=${user.access_token}&Product=Dashboard&bid=${RATE_ESTIMATE_BID}&Amount=${formData.loanAmount}${purpose}`;
  const fyiText = " (FYI, getting a rate estimate won’t affect your scores.)";

  return (
    <div>
      <p className="estimate-description">
        {displayText.description}
        {fyiText}
      </p>
      <div className="estimate-form">
        <Slider
          onChange={(value) => setValue("loanAmount", value)}
          formatLabel={(x) => `${toCurrency(x, false)}`}
          step={1000}
          minValue={minLimit}
          maxValue={maxLimit}
          title="How much?"
          colour="navy"
        />
        <p className="estimate-description">
          It only takes two minutes and won&apos;t affect your credit scores
        </p>
        <ButtonRow>
          <Button button="primary" theme="navy">
            <a
              href={widgetOfferLink}
              target="_blank"
              rel="noopener nofollow"
              onClick={() => {
                analyticsCustomEvent("clickedWidgetOffer", {
                  "Dashboard offer": "Rate estimate",
                  Location: "Dashboard credit scores home",
                  Personalisation:
                    displayText.moment === "Other"
                      ? "Default"
                      : displayText.moment,
                });
              }}
            >
              Get started
            </a>
          </Button>
        </ButtonRow>
      </div>
    </div>
  );
};
