import { useSelector } from "react-redux";
import { AppStore } from "../../../../store";
import { MoneyMomentCopy } from "../../../../types/personalised-rate-estimate";
import {
  selectPersonalisedRateEstimate,
  selectPersonalisedRateEstimateStatus,
} from "./personalised-rate-estimate.slice";

export const useGetPersonalisedRateEstimate = () => {
  return useSelector<AppStore, MoneyMomentCopy[]>(
    selectPersonalisedRateEstimate
  );
};

export const useGetPersonalisedRateEstimateStatus = () => {
  return useSelector<AppStore, string>(selectPersonalisedRateEstimateStatus);
};
