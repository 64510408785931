import { useLoadingStatus } from "@wisr/common";
import { Accordion, SkeletonLoader } from "@wisr/react-ui";
import React, { useState } from "react";
import { NavLink } from "../../shared/nav-link/nav-link.component";
import { WidgetComponent } from "../../widget.component";
import {
  equifaxOneBands,
  SCORE_PROVIDER,
} from "../score/score-credit-widget.consts";
import { GETSCORE_CREDIT_LOADING } from "../score/score-credit-widget.epic";
import { useGetScores } from "../score/score-credit-widget.hooks";
import { getScoreByProvider } from "../score/score-credit-widget.utils";
import { CREDIT_FAQ } from "../../../credit/faq/faq.constants";
import localStyle from "./understand-credit-widget.scss";

export const UnderstandCreditWidgetComponent = () => {
  const [missingScore, setMissingScore] = useState(false);
  const [scoreLoading] = useLoadingStatus(GETSCORE_CREDIT_LOADING);
  const creditScores = useGetScores();

  const equifaxScoreObj = getScoreByProvider(
    creditScores,
    SCORE_PROVIDER.EQUIFAX
  );
  const experianScoreObj = getScoreByProvider(
    creditScores,
    SCORE_PROVIDER.EXPERIAN
  );

  const scoreIsNegative =
    (equifaxScoreObj && equifaxScoreObj.score < 0) ||
    (experianScoreObj && experianScoreObj.score < 0);

  React.useEffect(() => {
    if (creditScores?.creditScoreSummaryList) {
      const hasScoreMissing = creditScores.creditScoreSummaryList.some(
        (score) => score.creditScoreDisplay !== "Has credit score"
      );
      setMissingScore(hasScoreMissing);
    }
  }, [creditScores]);

  return (
    <WidgetComponent widgetName="understand-credit" widgetType="credit">
      <style jsx>{localStyle}</style>
      {scoreLoading ? (
        <SkeletonLoader lines={4} />
      ) : (
        <>
          <div className="widget-title">
            <h2>Understand your scores</h2>
          </div>
          <div className="widget-data">
            {missingScore && (
              <>
                <Accordion title={"Why are one or more of my scores missing?"}>
                  {CREDIT_FAQ["Why are one or more of my scores missing?"]}
                </Accordion>
                <Accordion title={"What is a credit score?"}>
                  {CREDIT_FAQ["What is a credit score?"]}
                </Accordion>
                <Accordion title={"Why do I have multiple scores?"}>
                  {CREDIT_FAQ["Why do I have multiple scores?"]}
                </Accordion>
              </>
            )}
            {!missingScore && scoreIsNegative && (
              <>
                <Accordion title={"Why do I have a negative credit score?"}>
                  {CREDIT_FAQ["Why do I have a negative credit score?"]}
                </Accordion>
                <Accordion title={"How long until my scores improve?"}>
                  {CREDIT_FAQ["How long until my scores improve?"]}
                </Accordion>
                <Accordion
                  title={"I don’t think this is right. What should I do?"}
                >
                  {CREDIT_FAQ["I dont think this is right. What should I do?"]}
                </Accordion>
              </>
            )}
            {!missingScore &&
              ((equifaxScoreObj?.score || 0) >= equifaxOneBands[2].bandLimit ? (
                <>
                  <Accordion title={"What is a credit score?"}>
                    {CREDIT_FAQ["What is a credit score?"]}
                  </Accordion>
                  <Accordion title={"Why do I have multiple scores?"}>
                    {CREDIT_FAQ["Why do I have multiple scores?"]}
                  </Accordion>
                  <Accordion title={"What is a 'good' credit score?"}>
                    {CREDIT_FAQ["What is a 'good' credit score?"]}
                  </Accordion>
                </>
              ) : (
                <>
                  <Accordion title={"Why did I get this score?"}>
                    {CREDIT_FAQ["Why did I get this score?"]}
                  </Accordion>
                  <Accordion title={"What is a credit score?"}>
                    {CREDIT_FAQ["What is a credit score?"]}
                  </Accordion>
                  <Accordion title={"Why do I have multiple scores?"}>
                    {CREDIT_FAQ["Why do I have multiple scores?"]}
                  </Accordion>
                </>
              ))}
          </div>
          <div className="cta">
            <NavLink to="./faq">More on credit scores</NavLink>
          </div>
        </>
      )}
    </WidgetComponent>
  );
};
