import { classFormat, isDefined, useLoadingStatus } from "@wisr/common";
import { SkeletonLoader } from "@wisr/react-ui";
import dayjs from "dayjs";
import { Chart } from "highcharts";
import React, { useState } from "react";
import { LineGraphChartComponent } from "../../../charts/line-graph/line-graph-chart.component";
import { analyticsLoadCreditWidgetEvent } from "../../../shared/analytics/analytics.utils";
import { stringToDate } from "../../../shared/date.utils";
import { LineData, LineGraphLines } from "../../../types/graph";
import { ErrorWidgetComponent } from "../../shared/error/error-widget.component";
import { WidgetComponent } from "../../widget.component";
import {
  equifaxColour,
  experianColour,
  SCORE_PROVIDER,
} from "../score/score-credit-widget.consts";
import {
  useGetScores,
  useIdChecksSuccessful,
} from "../score/score-credit-widget.hooks";
import { CreditHistoryScore } from "../../../types/score-credit-widget";
import historyStyle from "./history-credit-widget.scss";
import { GETSCORE_CREDIT_LOADING } from "../score/score-credit-widget.epic";

interface ChartData {
  desktop: LineGraphLines;
  mobile: LineGraphLines;
}

export const HistoryCreditWidgetComponent: React.FC = () => {
  const [chartData, setChartData] = useState<ChartData | undefined>(undefined);

  const [chartObject, setChart] = useState({} as Chart);
  const [loading, loadingError] = useLoadingStatus(GETSCORE_CREDIT_LOADING);

  const scoreHistory = useGetScores();
  const idmResult = useIdChecksSuccessful();
  const isFirstUpdate = React.useRef(true);

  React.useEffect(() => {
    // Mandating that both yearly and half yearly should exist even though mobile view is optional
    if (
      scoreHistory?.yearlyCreditHistoryList &&
      scoreHistory.halfYearlyCreditHistoryList
    ) {
      const desktop = mapHistoryData(scoreHistory.yearlyCreditHistoryList);
      const mobile = mapHistoryData(scoreHistory.halfYearlyCreditHistoryList);
      setChartData({
        desktop,
        mobile,
      });
    }

    if (isFirstUpdate.current) {
      isFirstUpdate.current = false; // stop retriggering this block after firing once
      analyticsLoadCreditWidgetEvent("Credit score history");
    }
  }, [scoreHistory]);

  if (loadingError) {
    return (
      <WidgetComponent widgetName="credit-history-error" widgetType="credit">
        <ErrorWidgetComponent />
      </WidgetComponent>
    );
  }

  return (
    <WidgetComponent widgetName="credit-history" widgetType="credit">
      <style jsx>{historyStyle}</style>
      {loading ? (
        <div className="loading-wrapper">
          <SkeletonLoader lines={2} />
          <SkeletonLoader lines={4} />
        </div>
      ) : (
        <>
          <div className="widget-title">
            <h3>History</h3>
            {isDefined(chartObject) && isDefined(chartObject.series) && (
              <div className="legend-box">
                {chartObject.series.map((series) => {
                  if (series.name !== "hide") {
                    return (
                      <div
                        className={`legend ${classFormat(series.name)}`}
                        key={series.name}
                      >
                        <div className="circle"></div>
                        {series.name}
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>
          <p>
            View and monitor your credit health over the past{" "}
            <span className="desktop-only">12</span>
            <span className="mobile-only">6</span> months
          </p>
          {chartData && (
            <LineGraphChartComponent
              lines={chartData.desktop}
              mobileLines={chartData.mobile}
              chartCallback={setChart}
              hideData={!idmResult}
            />
          )}
        </>
      )}
    </WidgetComponent>
  );
};

export const getLatestScoreDate = (
  scoreHistory: CreditHistoryScore[],
  provider: string
) => {
  const filteredScores =
    Array.isArray(scoreHistory) && scoreHistory.length
      ? scoreHistory.filter(
          (score) => score.provider === provider && score.score !== null
        )
      : [];
  return filteredScores.length
    ? filteredScores.reduce((acc, curr) => {
        return dayjs(curr.scoreDate).isAfter(dayjs(acc.scoreDate)) &&
          dayjs(curr.scoreDate).isBefore(dayjs())
          ? curr
          : acc;
      })
    : ({
        scoreDate: "",
      } as CreditHistoryScore);
};

const transformDataToChart = (
  scoreHistoryList: CreditHistoryScore[],
  provider: string,
  colour: string,
  legendIndex: number
) => {
  const latestScoreDate = getLatestScoreDate(scoreHistoryList, provider);
  const isEquifax = provider === SCORE_PROVIDER.EQUIFAX;
  return {
    name: provider,
    legendIndex,
    colour: colour,
    legendLabel: `Last update ${stringToDate(latestScoreDate.scoreDate)}`,
    data: scoreHistoryList
      .filter((item) => item.provider.toLowerCase() === provider)
      .map((score) => {
        return isEquifax ? score.oneScoreComprehensive : score.score;
      }),
  } as LineData;
};

const mapHistoryData = (creditHistory: CreditHistoryScore[]) => {
  // Get the dates, removes any duplicates
  const categories: string[] = creditHistory
    .map((cat) => stringToDate(cat.scoreDate, "month"))
    .filter((item, index, self) => self.indexOf(item) === index);
  return {
    categories,
    data: [
      transformDataToChart(
        creditHistory,
        SCORE_PROVIDER.EXPERIAN,
        experianColour,
        0
      ),
      transformDataToChart(
        creditHistory,
        SCORE_PROVIDER.EQUIFAX,
        equifaxColour,
        1
      ),
    ],
  };
};
