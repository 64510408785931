import { useForm } from "@wisr/common";
import { Card, StatusCallout } from "@wisr/react-ui";
import React from "react";
import { useDispatch } from "react-redux";
import { CreditProfileFormDefaults } from "../../forms/credit-profile/credit-profile-form.constants";
import { FormProfileMobileVerification } from "../../forms/credit-profile/mobile-verification/mobile-verification";
import { useGetVerificationCodeState } from "../../forms/credit-profile/mobile-verification/mobile-verification.hooks";
import { PageWrapperComponent } from "../../layout/page-wrapper/page-wrapper-layout.component";
import { wisrProfileGetAction } from "../../redux/wisr-profile/wisr-profile.actions";
import { scoreCreditGetAction } from "../../widgets/credit/score/score-credit-widget.actions";
import { calloutMessage } from "./verify-mobile.consts";
import style from "./verify-mobile.scss";

export const VerifyMobile = () => {
  const dispatch = useDispatch();
  const verificationCodeState = useGetVerificationCodeState();
  const { fieldAdapter, formData } = useForm(CreditProfileFormDefaults);
  const mobileVerified = verificationCodeState.isVerified;

  React.useEffect(() => {
    if (mobileVerified) {
      dispatch(wisrProfileGetAction());
      dispatch(scoreCreditGetAction());
    }
  }, [dispatch, mobileVerified]);

  return (
    <div className="verify-mobile-wrapper">
      <style jsx>{style}</style>
      <PageWrapperComponent size="half">
        <div className="prefix">
          <Card>
            <div className="verify-mobile">
              <h2>What&apos;s your number?</h2>
              <p>
                We&apos;re keen on security, so we&apos;ll send you a code to
                verify your mobile number.
              </p>
              <FormProfileMobileVerification
                fieldAdapter={fieldAdapter}
                formData={formData}
                disabled={false}
                standalone={true}
              />
            </div>
          </Card>
        </div>
        <div className="suffix">
          <StatusCallout type="warning" message={calloutMessage} />
        </div>
      </PageWrapperComponent>
    </div>
  );
};
