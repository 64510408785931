import { RefObject, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { isBrowser } from "@wisr/common";

export function useComponentSize(componentRef: RefObject<HTMLDivElement>) {
  const [size, setSize] = useState(0);
  const isBrowserVar = isBrowser();

  useEffect(() => {
    if (isBrowserVar) {
      const debouncedUpdateSize = debounce(() => {
        componentRef.current && setSize(componentRef.current["offsetWidth"]);
      }, 100);

      window.addEventListener("resize", debouncedUpdateSize);
      debouncedUpdateSize();
      return () => window.removeEventListener("resize", debouncedUpdateSize);
    }
  }, [componentRef, isBrowserVar]);
  return size;
}
