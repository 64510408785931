import { capitalise, isDefined, isBrowser } from "@wisr/common";
import HighCharts, { Chart, YAxisTitleOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighChartsMore from "highcharts/highcharts-more";
import React, { useRef } from "react";
import { LineData, LineGraphLines } from "../../types/graph";
import { useComponentSize } from "./line-graph-chart.utils";
import graphStyle from "./line-graph.scss";

interface LineProps {
  lines: LineGraphLines;
  mobileLines?: LineGraphLines;
  chartCallback?: (chart: Chart) => void;
  hideData?: boolean;
}

export const LineGraphChartComponent: React.FC<LineProps> = ({
  lines,
  mobileLines,
  chartCallback,
  hideData = false,
}) => {
  const chartRef = useRef(null);
  const chartWidth = useComponentSize(chartRef);

  if (isDefined(lines)) {
    /* Use Highcharts more library even if we don't need it
     * It's to prevent clashes for charts that do require it,
     *  but basically we will ned it for every chart now.
     *  Also, we need to check for window since More doesn't play well with SSR
     */
    if (isBrowser()) {
      HighChartsMore(HighCharts);
    }

    const chartOptions: HighCharts.Options = {
      chart: {
        height: 200,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: hideData ? "No history available" : undefined,
        floating: true,
        verticalAlign: "top",
        y: 70,
        style: {
          textTransform: "none",
          fontFamily: "Inter",
          textAlign: "center",
          fontSize: "16px",
          color: "#617280",
        },
      },

      legend: {
        enabled: false,
      },

      xAxis: {
        categories: lines.categories,
        minPadding: 0.05,
        type: "datetime",
        dateTimeLabelFormats: {
          month: "%b",
        },
        lineColor: "#DFE3E8",
        lineWidth: 1,
        labels: {
          y: 30,
          style: {
            fontFamily: "Inter",
            textTransform: "uppercase",
            textAlign: "center",
            fontSize: "14px",
            color: "#617280",
          },
        },
      },

      plotOptions: {
        line: {
          dataLabels: {
            enabled: false,
          },
          marker: {
            enabled: true,
            symbol: "circle",
            radius: 3,
          },
          lineWidth: 2,
        },
        series: {
          connectNulls: true,
        },
      },

      tooltip: {
        useHTML: true,
        backgroundColor: "#258184",
        borderColor: "#258184",
        borderWidth: 1,
        borderRadius: 8,
        padding: 0,
        shape: "circle",
        shadow: false,
        style: { pointerEvents: "auto" },
        positioner: function (labelWidth, labelHeight, point) {
          const tooltipX = point.plotX + 52 - labelWidth / 2;
          const tooltipY = point.plotY - labelHeight;
          return {
            x: tooltipX,
            y: tooltipY,
          };
        },
        formatter: /* istanbul ignore next */ function () {
          return `<div class="wrapper"><span class="label">${this.y}</span></div>`;
        },
      },

      yAxis: lines.data.map((line, index) => {
        return {
          title: {
            enabled: false,
          } as YAxisTitleOptions,
          labels: {
            // Only show the lines on the first occurance
            enabled: index === 0,
            style: { color: "#617280" },
          },
          showEmpty: true,
          tickPositions: [0, 600, 1200],
          showFirstLabel: true,
          showLastLabel: true,
          gridLineDashStyle: "Solid",
          min: 0,
          top: "0%",
        };
      }),

      series:
        chartWidth && chartWidth > 560
          ? lineMapData(lines.data, hideData)
          : mobileLines && lineMapData(mobileLines.data, hideData),

      responsive: mobileLines && {
        rules: [
          {
            condition: {
              maxWidth: 560,
            },
            chartOptions: {
              xAxis: {
                categories: mobileLines.categories,
                max: 5, // Some inconsistency with tickAmount that shows more than max
              },
              legend: {
                layout: "horizontal",
              },
            },
          },
        ],
      },
    };

    return (
      <div ref={chartRef}>
        <style jsx>{graphStyle}</style>
        <HighchartsReact
          highcharts={HighCharts}
          options={chartOptions}
          callback={(chart: Chart) => {
            chartCallback && chartCallback(chart);
          }}
          containerProps={{ className: "container" }}
        />
      </div>
    );
  }
  return <HighchartsReact />;
};

const lineMapData = (
  lines: LineData[],
  empty?: boolean
): HighCharts.SeriesOptionsType[] => {
  const dataArray = lines.map((data, index) => {
    return [
      {
        type: "line",
        name: capitalise(data.name),
        data: empty ? [] : data.data,
        color: data.colour,
        yAxis: index,
        showInLegend: true,
        marker: {
          fillColor: data.colour,
          lineColor: data.colour,
          lineWidth: 2,
        },
      },
    ];
  });
  return [dataArray as any].flat(2);
};
