import { isBrowser } from "@wisr/common";
import { PageProps } from "gatsby";
import React from "react";
import { useDispatch } from "react-redux";
import { BenefitsCreditComponent } from "../../credit/benefits/benefits-credit.component";
import { VerifyMobile } from "../../credit/verify-mobile/verify-mobile";
import { FinancialMoodModal } from "../../financial-mood/financial-mood-modal";
import { getVerifiedMobileNumbers } from "../../forms/credit-profile/mobile-verification/mobile-verification.utils";
import { wisrProfileGetAction } from "../../redux/wisr-profile/wisr-profile.actions";
import { useGetWisrProfile } from "../../redux/wisr-profile/wisr-profile.hooks";
import {
  analyticsCustomEvent,
  analyticsViewCreditPageEvent,
  brazeLoaded,
} from "../../shared/analytics/analytics.utils";
import { isNotEmpty } from "../../shared/common.utils";
import { isDebugMode } from "../../shared/config.utils";
import { InAppMessage } from "../../types/in-app-message";
import { useGetUser } from "../../user/user.hooks";
import { PersonalisedRateEstimateWidget } from "../../widgets/borrowing/rate-estimate/personalised/personalised-rate-estimate-widget";
import { HistoryCreditWidgetComponent } from "../../widgets/credit/history/history-credit-widget.component";
import { ScoreCreditWidgetComponent } from "../../widgets/credit/score/score-credit-widget";
import { UnderstandCreditWidgetComponent } from "../../widgets/credit/understand/understand-credit-widget";
import { WidgetWrapper } from "../../widgets/widget.component";
import pageStyle from "./index.global.scss";

const HomePage: React.FC<PageProps> = () => {
  const dispatch = useDispatch();
  const user = useGetUser();
  const wisrProfile = useGetWisrProfile();
  const braze = brazeLoaded();
  const isFirstUpdate = React.useRef(true);
  const hasAnyLoans = user.loanCount > 0 || user.applicationCount > 0;
  const showRateEstimate = user.hasCreditAccount && !hasAnyLoans;
  const hasVerifiedMobile = getVerifiedMobileNumbers(wisrProfile).length > 0;

  React.useEffect(() => {
    analyticsViewCreditPageEvent("Credit scores home");
  }, []);

  React.useEffect(() => {
    if (braze) {
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
        brazeSubscribeMessages();
      }
    }
    /* istanbul ignore next - not covered in cleanup */
    return () => {
      if (braze) {
        window.appboy.removeAllSubscriptions();
      }
    };
  }, [braze]);

  React.useEffect(() => {
    if (wisrProfile === undefined) {
      dispatch(wisrProfileGetAction());
    }
  }, [dispatch, wisrProfile]);

  if (!isBrowser()) {
    return null;
  }

  if (!user.hasCreditAccount) {
    return <BenefitsCreditComponent />;
  }

  if (!hasVerifiedMobile && isNotEmpty(wisrProfile) && !isDebugMode()) {
    return <VerifyMobile />;
  }

  return (
    <WidgetWrapper>
      <style jsx>{pageStyle}</style>
      <FinancialMoodModal />
      <div className="prefix">
        <ScoreCreditWidgetComponent />
        <HistoryCreditWidgetComponent />
      </div>
      <div className="suffix">
        {showRateEstimate && <PersonalisedRateEstimateWidget />}
        <UnderstandCreditWidgetComponent />
      </div>
    </WidgetWrapper>
  );
};

export default HomePage;

//this function is in this file due to test issues with mocking Braze functions
//TODO: move this back to analytics.utils.ts when the Braze SDK is installed
const brazeSubscribeMessages = () => {
  /* istanbul ignore else */
  if (brazeLoaded()) {
    let shouldDisplay = true;
    try {
      window.appboy.subscribeToInAppMessage((inAppMessage: InAppMessage) => {
        const shouldNotDisplay =
          !window.appboy.isPushSupported() ||
          window.appboy.isPushPermissionGranted() ||
          window.appboy.isPushBlocked();
        const firstInAppMessageBtn =
          inAppMessage.buttons && inAppMessage.buttons[0];
        const inAppMessageId = inAppMessage.extras?.msgId;

        if (inAppMessageId === "pushPrimer") {
          if (shouldNotDisplay) {
            shouldDisplay = false;
          }
          if (firstInAppMessageBtn) {
            firstInAppMessageBtn.subscribeToClickedEvent(
              /* istanbul ignore next */ () => {
                window.appboy.registerAppboyPushMessages(() =>
                  analyticsCustomEvent("registeredPushNotifications")
                );
              }
            );
          }
        }

        if (shouldDisplay) {
          window.appboy.display.showInAppMessage(inAppMessage);
          analyticsCustomEvent(
            "presentedInAppMessage",
            inAppMessageId && {
              Id: inAppMessageId,
            }
          );
        }
      });
    } catch {
      console.error("failed to subscribe to messages");
    }
  }
};
