import { OcrFeatureFlagStates } from "./ocr.constants";

export const redirectToOcr = (flagState: string, email: string) => {
  if (flagState === OcrFeatureFlagStates.ON) {
    return true;
  }
  if (flagState === OcrFeatureFlagStates.WISR) {
    return email.includes("@wisr.com.au");
  }
  return false;
};

export const checkScoresLink = (
  flagState: string,
  email: string | undefined
) => {
  return email && redirectToOcr(flagState, email)
    ? "/credit/verification/"
    : "/credit/create-profile/";
};
